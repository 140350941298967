/* You can add global styles to this file, and also import other style files */
/* :root {
    --primary: 23, 71, 112;
    --primary-50: rgba(var(--primary), 0.5);
    --primary-75: rgba(var(--primary), 0.25);
    --secondary: 0, 178, 226;
    --secondary-50: rgba(var(--secondary), 0.5);
    --secondary-75: rgba(var(--secondary), 0.25);
    --background-color: 240, 241, 246;
    --background-color-50: rgba(var(--background-color), 0.5);
    --font-primary: 23, 71, 112;
    --font-primary-50: rgba(var(--font-primary), 0.5);
    --font-secondary: 0, 178, 226;
    --font-secondary-50: rgba(var(--font-secondary), 0.5);
} */

/* new root */
:root {
  
  --primary: 8, 50, 226;
  --secondary: 0, 178, 226;
  --font-primary: 0, 0, 0;
  --font-secondary: 8, 50, 226;
  --background-color: 240, 241, 246;
  
  --primary-50: rgba(var(--primary), 0.5);
  --primary-25: rgba(var(--primary), 0.25);
  --primary-10: rgba(var(--primary), 0.1);


  --secondary-50: rgba(var(--secondary), 0.5);
  --secondary-25: rgba(var(--secondary),0.25);
    
  --font-primary-50: rgba(var(--font-primary), 0.5);
  --font-secondary-50: rgba(var(--font-secondary), 0.5);
    
  --background-color-50: rgba(var(--background-color), 0.5);

  --font-color: #2d2d2d;
  --new-dark: #09238f;
  --red: #9E0000;
  --warning: 221, 196, 140;
  --form-bg: #ffffff;
}

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400&display=swap");
// Import bootstrap functions
@import "../node_modules/bootstrap/scss/functions";

//Custom variables
$primary: #174770;
$primary-50: tint-color($primary, 50%);
$primary-75: tint-color($primary, 75%);
$secondary: #00b2e2;
$secondary-50: tint-color($secondary, 50%);
$secondary-75: tint-color($secondary, 75%);

$font-primary: #174770;
$font-primary-50: tint-color($font-primary, 50%);
$font-secondary: #00b2e2;
$font-secondary-50: tint-color($font-secondary, 50%);
$custom-bg: #f0f1f6;
$custom-bg-50: tint-color($custom-bg, 50%);

$white: white;
$black: black;

// Import variables and mixins
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

//Custom theme maps
$custom-theme-colors: (
  "primary-50": $primary-50,
  "primary-75": $primary-75,
  "secondary-50": $secondary-50,
  "secondary-75": $secondary-75,
  "font-primary": $font-primary,
  "font-primary-50": $font-primary-50,
  "font-secondary": $font-secondary,
  "font-secondary-50": $font-secondary-50,
  "custom-bg": $custom-bg,
  "custom-bg-50": $custom-bg-50,
  "white": $white,
  "black": $black,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// $utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
// $utilities-text-colors: map-loop(
//   $utilities-colors,
//   rgba-css-var,
//   "$key",
//   "text"
// );
// $utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// Import all bootstrap Sass for customization
@import "../node_modules/bootstrap/scss/bootstrap";

/*----------------------------------------------
	1.0 General Style
-----------------------------------------------*/

/*----------------------------------------------
	1.0 General Style
-----------------------------------------------*/

html {
  font-size: 62.5%;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Readex Pro", sans-serif;
  font-size: 1.4rem;
  line-height: 1.85;
  color: rgb(var(--font-primary));
  background: rgb(var(--background-color));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Readex Pro", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: inherit;
}

p {

  color: var(--font-primary);
  margin: 0;
  padding: 0;
}

a {
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  outline: none;
  text-decoration: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  outline: none;
  cursor: pointer;
}

textarea {
  resize: none;
}

input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: var(--bs-font-primary);
}
input:-moz-placeholder,
.form-control:-moz-placeholder {
  color: var(--bs-font-primary);
}
input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: var(--bs-font-primary);
}
input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: var(--bs-font-primary);
}

button,
.btn {
  border: 0;
  background: transparent;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.primary-color {
  color: var(--bs-font-primary);
}

.primary-bg {
  background: var(--bs-custom-bg);
}

.ugf-bg {
  background-size: cover;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #b7240b !important;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #b7240b !important;
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
}
.form-group {
  .validation-text {
    height: 18px;
  }
}

.margin-top-36 {
  margin-top: -36px !important;
}

// ::-webkit-scrollbar {
//   width: 0px;
//   background: transparent;
// }

.toUpper {
  text-transform: uppercase !important;
}

.toast-container {
  width: 100% !important;
  margin-top: 15px;

  .ngx-toastr {
    box-shadow: 0 0 3px #999 !important;
  }
  .toast-top-full-width .ngx-toastr {
    width: 100% !important;
  }
}
.pas {
  word-break: break-word;
}

.pas > b {
  font-weight: bold !important;
}

.f20 {
  font-size: 20px !important;
}

#brandName {
  word-break: break-word;
  max-width: 150px;
  font-size: 20px;
}

#loaderSection {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 0%, 30%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderSize {
  width: 8rem;
  height: 8rem;
}

.bussinessLogo {
  max-height: 50px;
  max-width: 150px;
}

.preline {
  white-space: pre-line !important;
}

.width66 {
  width: 66.66% !important;
}

.width40 {
  width: 40% !important;
}

.mrg4 {
  margin: 4px !important;
}

.checkboxStyle {
  justify-content: start;
  margin-top: -3px;
}

@media (max-width: 575.98px) {
  .ufg-main-container .custom-form-container .right-column {
    max-width: 100% !important;
  }

  .widget-container {
    min-width: 100% !important;

    .form-shadow.height-padding {
      padding: 30px 15px !important;
    }

    .pay-choice-container {
      // min-width: 100% !important;
      // padding: 20px 0px !important;

      .pay-calc-holder {
        width: 100% !important;
      }

      .pay-box .choice-box {
        width: 100% !important;
        min-width: 100% !important;
        padding: 5px !important;
        margin-top: 10px !important;
      }
    }
  }

  .ufg-main-container {
    padding-bottom: 20px !important;
    padding-top: 120px !important;
  }
  .ugf-form {
    min-width: 100% !important;
  }

  .form-group .contact-radio-container {
    flex-direction: column !important;
  }

  footer.footer-box {
    flex-direction: column !important;
    padding: 1% 5% !important;
  }

  footer.footer-box .link-box .footer-link {
    padding: 0 0px !important;
    margin-top: 10px;
  }

  // .navigation .nav-btns .prog-btn {
  //   width: 125px !important;
  // }

  .bussinessLogo {
    max-height: 50px;
    max-width: 75px !important;
    width: 120px;
  }

  // .navigation .nav-btns {
  //   gap: 5px;
  // }

  // .ugf-nav .navigation {
  //   min-width: 100% !important;
  // }

  .payment-info-mobile {
    min-width: 300px !important;
    max-width: 330px !important;
  }

  // .modal-content {
  //   max-width: 365px !important;
  //   min-width: 350px !important;
  // }

  .modal-content .modal-buttons .modal-btn {
    width: 160px !important;
  }

  // .pay-choice-container.pay-summary-box {
  //   flex-direction: column !important;
  // }

  .pay-choice-container.pay-summary-box .completed-info.column {
    width: 100% !important;
    // order: 2;
  }

  .pay-choice-container.pay-summary-box .summary-info.column {
    width: 100% !important;
  }
}

div.row .input-col-container .error-message {
  display: block !important;
  visibility: visible !important;
  color: #b7240b !important;
  font-weight: 400 !important;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
}
.pay-choice-container .pay-calc-holder {
  display: flex;
  width: 70%;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;
  align-items: center !important;
  margin-bottom: 20px;
}

.ugf-form .btn {
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding: 0;
  border-radius: 8px;
  background: rgb(var(--primary)) !important;
  color: #fff !important;
  font-size: 1.5rem;
  font-weight: 100;
  outline: none;
  box-shadow: none;
}

.pay-choice-container .button-container .next-button {
  margin: 20px 0;
  padding: 10px 10px 5px;
  width: 45%;
  height: 40px;
  background: rgb(var(--primary)) !important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 10px 20px #00000040;
}

.widget-container .main-info-container .buttons-holder .btn {
  width: 40%;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgb(var(--font-secondary));
  padding-top: 0px !important;
}

.btn-group .selected {
  background: rgb(var(--secondary)) !important;
  border-radius: 8px;
  min-width: 155px;
  max-height: 30px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff !important;
  opacity: 1 !important;
}

.footerBox {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
}

.item-right {
  float: right;
  width: 3%;
}

// .header {
//   font-size: 22px;
//   line-height: 1.28571429em;
//   font-weight: 500px !important;
//   padding-bottom: 12px;
// }

.inline {
  position: relative;
  transition: margin-top 0.5s;
}

.inline label {
  position: absolute;
  top: 8px;
  left: 10px;
  transition: top 0.5s, left 0.5s;
  pointer-events: none;
  background-color: white !important;
  width: auto !important;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 8px;
}
.inline label.animate {
  top: -10px;
  left: 6px;
}
input::placeholder {
  opacity: 0 !important;
}
.animated {
  top: -10px !important;
  left: 6px !important;
}

.mand label::before {
  content: "* ";
  color: #b30909;
}

.footer-link {
  color: rgb(var(--font-primary));
}

#calc-button {
  display: flex;
}

.payment-form {
  // padding: 0rem 2rem !important;
}

.terms {
  font-size: 10px;
  margin: 0;
  line-height: 16px;
  word-break: break-word !important;
  text-align: justify;
}

#brandName {
  color: rgb(var(--font-primary));
}

.payment-body .header {
  margin-bottom: 0px !important;
}

.payment-body .navigation {
  padding: 0px;
  margin-bottom: 1rem !important;
}

.confirm-button {
  color: var(--form-bg) !important;
  text-decoration: none !important;
  display: flex !important;
}

.errorIcon {
  visibility: visible !important;
}

.errorMessage {
  visibility: visible !important;
}

#ach-details {
  display: flex !important;
}

.left0 {
  left: 0% !important;
}

.left50 {
  left: 50% !important  ;
}

.payment-details {
  gap: 1.3rem !important;
}

input[type="phone"] {
  border: 0.5px solid var(--font-primary-50) !important;
}

input[type="phone"]:focus {
  border: 1px solid rgb(var(--font-primary)) !important;
}

input[type="phone"].error {
  border: 1px solid #b7240b !important;
}

input[type="phone"].error {
  border: 1px solid #b7240b !important;
}

.sendEmailReceipt {
  .form-control:focus {
    box-shadow: none !important;
  }
}
.cp {
  cursor: pointer;
}

#bankAccNum,
.payment-form .input-section input,
.payment-form .input-section select {
  // border: 1px solid var(--font-primary-50) !important;
  width: 100%;
}

.payment-body .navigation .prog-btn.active {
  color: rgb(var(--font-primary));
  border-bottom:3px solid rgb(var(--font-secondary)) !important;
} 

.modal-open {
  overflow: inherit !important;
}

.terms-section > .custom-checkbox{
  min-width: 16px;
}

.card-holder .choice-card {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  background-color: rgb(var(--background-color));
  border: 1px solid var(--font-primary-50);
  border-radius: 8px;
  opacity: 0.75;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  color: rgb(var(--font-primary));
  padding: 1rem;
  cursor: pointer;
  flex: 1 1 50%;
  min-width: 205px;
}

.payment-body .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2rem  !important;
}



.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}

.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.top-0 {
  top: 0 !important;
}
.position-absolute {
  position: absolute !important;
}
.d-flex {
  display: flex !important;
}

.bg-white {
  background-color: white;
}


#bgcolor{
  background-color: #173354;
}
.w-50 {
  width: 50% !important;
}

.flex-column {
  flex-direction: column !important;
}

div {
  display: block;
}

.text-center {
  text-align: center !important;
}

img,
svg {
  vertical-align: middle;
}

#logosize{
  margin-bottom: 3rem;
}

#textsize {
  margin-top: -1rem !important;
}

h4 {
  font-size: 20px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

#receipt-icon {
  cursor: pointer;
}

/* New section for receipt modal popup */
.modal-dialog {
  max-width: 800px;
}
.modal-header {
  border: none !important;
  background: transparent !important;
}
.modal svg, .modal path {
  pointer-events: none;
}
.modal .print-icon:hover, .modal .email-icon:hover {
  color: #174770 !important;
}

.receiptSummary .modal-content,
.receiptSummary .modal-dialog,
.receiptSummary{
  border: 0 !important;
  background: transparent !important;
  max-width: 800px;
  max-height: calc(135vh - 265px) !important;
  overflow-y: auto !important;
}

 .receiptSummary .label{
  display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: initial !important;
}


.receiptEmail{
  max-width: 425px  !important;
  border-radius: 8px !important;
  padding: 20px !important;
  display: flex !important;
  justify-content: stretch !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  pointer-events: auto !important;
  background-clip: padding-box !important;
  outline: 0 !important;
}
.emailMessage{
  color: var(--font-primary);
  word-break: break-all;
}

.price-section {  
  max-width: 330px !important;  
}

.payment-body .header .business-logo img {
  max-width: 410px !important;
  max-height: 150px !important;
}


.payment-form .input-section.error input {
  border-color: var(--red) !important;
}
.payment-form .input-section input:focus {
  border: 2px solid rgb(var(--primary))!important;
}
.payment-form .input-section.error input:focus {
  border: 2px solid var(--red) !important;
}
.warning-box .info-icon.error {
  background-color: var(--red) !important;
}
.input-section input[type="phone"] {
  width: 100%;
}
label.animate, label[for="inputPhone"] {
  top: -6px;
  font-size: 10px;
}
label[for="inputPhone"] {
  z-index: 2;
.input-section:has(.custom-toggle) span.label {
  margin-bottom: 0.5rem;
}
}
.custom-toggle {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.custom-toggle>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(var(--font-primary));
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  width: 50%;
  height: 100%;
  cursor: pointer;
  transition: all 0.4s;
  line-height: 1;
}
  .custom-toggle>div:first-child {
      border-right: 1px solid rgb(var(--font-primary));;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
      .custom-toggle>div:first-child.active {
          border-color: rgb(var(--primary));
      }
      .custom-toggle>div:first-child.active,
      .custom-toggle:has(div:last-child.active)>div:first-child {
          border-right: 1px solid rgb(var(--primary));
      }
      .custom-toggle>div:last-child.active {
          border-color: rgb(var(--primary));
      }
  .custom-toggle>div:last-child {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  }
  .custom-toggle>div .toggle-name {
      flex: 1 1 auto;
  }
  .custom-toggle>div .toggle-icon {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .custom-toggle>div.active {
      background-color: rgba(var(--primary), 0.2);
      color: rgb(var(--primary));
  }
  .material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.payment-select {
  display: grid !important;
  gap: 0.5rem;
}
  .payment-select .selector-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
  }
.selector-box .paySelect {
  padding: 1.5rem;
  border-radius: 8px;
  border-style: solid;
  border-color: rgb(var(--font-primary)) !important;
  border-width: 1px;
  font-size: 22px;
  font-weight: 700;
  color: rgb(var(--font-primary)) !important;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}
  .selector-box .paySelect i {
      font-size: 35px;
  }
  .selector-box .paySelect.select,
  .selector-box .paySelect:not(.select, .disabled):hover {
      color: rgb(var(--primary)) !important;
      border-color: rgb(var(--primary)) !important;
      border-width: 2px;
  }
  .selector-box .paySelect.disabled {
      background-color: rgba(var(--font-primary), 0.1) !important;
      border-color: rgba(var(--font-primary), 0.5) !important;
      color: rgba(var(--font-primary), 0.5) !important;
  }

  .payment-form .input-section input, .payment-form .input-section select {
    border: 1px solid rgb(var(--font-primary)) !important;
}
.terms-container {
  font-size: 16px;
  color: rgb(var(--font-primary)) !important;
  font-family: 'Readex Pro' !important;
}

.payment-body .payment-form {
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 8px;
}
#shopperReceipt .header {
  margin-bottom: 0rem !important;
}
#merchantReceipt .email-body, #shopperReceipt .email-body {
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 0!important;
  box-shadow: 0 10px 20px #fff!important;
  background-color: #fff!important;
}
.receiptCloseBtn {
  background-color: #fff!important;
}